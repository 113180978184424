import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ParamState} from "../../../../shared/model-states/param.model";
import {Observable} from "rxjs/Observable";
import {PostFilterParam} from "../models/post-filter-param";
import {PreFilterParam} from "../models/pre-filter-param";

@Injectable({
  providedIn: 'root'
})
export class SmartQueryService {
  private BASE_URL = '/data-canva-api/protected/smartQuery';

  private _referenceEntityFormSetting: any[] = []

  private _postFilterParam: PostFilterParam = null

  private _preFilterMainParam: PreFilterParam = null

  private _preFilterRefParam: PreFilterParam[] = []

  private _savedParam: any = null;

  private _mainEntityName: string = null;

  private _mainEntityId: number = null;

  constructor(private http: HttpClient) {
  }

  updateParams(paginator, sorting) {
    let params = new ParamState();
    params.draw = 1;
    params.start = (paginator.page - 1) * paginator.pageSize;
    params.length = paginator.pageSize;
    params.searchValue = "";
    params.orderColumn = sorting.column;
    params.orderDirection = sorting.direction;
    return params;
  }

  getAllSmartQueryName() {
    const url = `${this.BASE_URL}/getAllSmartQueryName`;
    return this.http.get(url);
  }

  getMainEntityHeader(daeID) {
    const url = `${this.BASE_URL}/getMainEntityHeader?daeID=${daeID}`;
    return this.http.get(url);
  }

  getReferenceEntityHeader(referenceID, erID) {
    const url = `${this.BASE_URL}/getReferenceEntityHeader?daeID=${referenceID}&erID=${erID}`;
    return this.http.get(url);
  }

  createSmartQuery(formData) {
    const url = `${this.BASE_URL}/createSmartQuery`;
    return this.http.post(url, formData);
  }

  getSmartQuerySampleTable(params) {
    console.log('params', params);
    const url = `${this.BASE_URL}/getSmartQuerySampleTable`;
    return this.http.get(url, {params})
  }

  getSmartQueryTable(params, sqID) {
    const url = `${this.BASE_URL}/getSmartQueryTable`;
    params.sqID = sqID;
    return this.http.get(url, {params});
  }

  getSmartQueryList(params) {
    const url = `${this.BASE_URL}/getSmartQueryList`;
    return this.http.get(url, {params});
  }

  getSmartQueryDetail(params) {
    const url = `${this.BASE_URL}/getSmartQueryDetail`;
    return this.http.get(url, {params});
  }

  getSmartQueryFromAdminSetting() {
    const url = `${this.BASE_URL}/getSmartQueryFromAdminSetting`;
    return this.http.get(url);
  }

  saveSmartQuery(formData) {
    const url = `${this.BASE_URL}/saveSmartQuery`;
    return this.http.post(url, formData);
  }

  saveSmartQueryV2(formData) {
    const url = `${this.BASE_URL}/saveSmartQueryV2`;
    const params = new HttpParams()
      .set('sqID', formData.sqID)
      .set('mainID', formData.mainID)

    const body = {smartQueryParam: formData.smartQueryParam, columnStates:formData.columnStates, userCustomizedDisplayName: formData.userCustomizedDisplayName }

    return this.http.post(url,body, { params: params },);
  }

  saveSmartQueryToDW(sqID: number, outputDaeName: string, selectedEngine: string, columnStates) {
    const url = `/data-canva-api/public/smartQuery/saveSmartQueryToDW`;
    const body = {
      columnStates:JSON.stringify(columnStates)
    }


    const params = new HttpParams()
      .set('sqID', sqID)
      .set('dataEntityName', outputDaeName)
      .set("engineUsed", selectedEngine)

    return this.http.post(url,body,{ params: params });
  }

  previewSmartQuery(formData) {
    const url = `${this.BASE_URL}/previewSmartQuery`;
    return this.http.post(url, formData);
  }

  previewSmartQueryByFlink(objectForParam) {
    const url = `/data-canva-api/public/smartQuery/previewSmartQueryByFlink`;

    const params = new HttpParams()
      .set('sqID', objectForParam.sqID)
      .set('previewRunRecords', objectForParam.previewRunRecords)
      .set('mainID', objectForParam.mainID)
      .set('orderColumn', objectForParam.orderColumn)
      .set('orderDirection', objectForParam.orderDirection)

    const body = {smartQueryParam: objectForParam.smartQueryParam,
      columnStates:objectForParam.columnStates,
      userCustomizedDisplayName:objectForParam.userCustomizedDisplayName,
      customEditObject:objectForParam.customEditObject,
    }

    return this.http.post(url,body, { params: params },);
  }

  previewSmartQueryV3Trial(objectForParam) {
    const url = `/data-canva-api/public/smartQuery/previewSmartQueryV3Trial`;

    const params = new HttpParams()
      .set('sqID', objectForParam.sqID)
      .set('previewRunRecords', objectForParam.previewRunRecords)
      .set('mainID', objectForParam.mainID)
      .set('orderColumn', objectForParam.orderColumn)
      .set('orderDirection', objectForParam.orderDirection)

    const body = {
      smartQueryParam: objectForParam.smartQueryParam,
      columnStates:objectForParam.columnStates,
      userCustomizedDisplayName:objectForParam.userCustomizedDisplayName,
      customEditObject:objectForParam.customEditObject,
    }

    return this.http.post(url,body, { params: params },);
  }

  getPreviewTableResult(params) {
    const url = `${this.BASE_URL}/getPreviewTableResult`;
    return this.http.get(url, {params});
  }

  startSmartQuery(sqId) {
    const url = `${this.BASE_URL}/startSmartQuery`;
    let params = {"sqID": sqId}
    return this.http.get(url, {params});
  }

  startSmartQueries(sqIdList) {
    const url = `${this.BASE_URL}/startSmartQueries`;
    let params = {"sqIDList": sqIdList};
    return this.http.get(url, {params})
  }

  retrySmartQuery(sqId) {
    const url = `${this.BASE_URL}/retrySmartQuery`;
    let formData = new FormData();
    formData.append("sqID", sqId);
    return this.http.post(url, formData);
  }

  retrySmartQueries(sqIdList) {
    const url = `${this.BASE_URL}/retrySmartQueries`;
    let formData = new FormData();
    formData.append("sqIDList", sqIdList);
    return this.http.post(url, formData)
  }

  checkQueryDependency(id) {
    const url = `${this.BASE_URL}/checkQueryDependency`;
    let formData = new FormData();
    formData.append("sqID", id);
    return this.http.post(url, formData);
  }

  checkQueriesDependency(idList) {
    const url = `${this.BASE_URL}/checkQueriesDependency`;
    let formData = new FormData();
    formData.append("sqIDList", idList)
    return this.http.post(url, formData);
  }

  deleteSmartQuery(sqId) {
    const url = `${this.BASE_URL}/deleteSmartQuery`;
    let params = {"sqID": sqId};
    return this.http.delete(url, {params});
  }

  deleteSmartQueries(sqIdList) {
    const url = `${this.BASE_URL}/deleteSmartQueries`;
    let params = {"sqIDList": sqIdList};
    return this.http.delete(url, {params});
  }

  startExportFile(sqId) {
    const url = `${this.BASE_URL}/startExportFile`;
    let params = {"sqID": sqId}
    return this.http.get(url, {params});
  }

  getExportFile(token, sqID) {
    return `${this.BASE_URL}/exportFile?token=` + token + `&sqID=` + sqID;
  }

  getExportErrorReport(token, sqID) {
    return `${this.BASE_URL}/exportErrorReport?token=` + token + `&sqID=` + sqID;
  }

  getQueryDataEntity(daeID:number, fuzzyKeyWord:string, ): Observable<any> {
    const url = `/data-canva-api/protected/simpleQuery/queryDataEntity`
    const params = {
      daeID: daeID,
      fuzzyKeyWord: fuzzyKeyWord,
      length: 100,
      start: 0,
      draw: 1,
      mode:"query",
      searchValue: "",
      orderColumn:"",
      orderDirection:"",
      logicSequence:"",
      queryList:""
    }
    return this.http.get(url, {params})
  }

  getEntityNameList(inputEntityName: string) {
    const url = `${this.BASE_URL}/getEntityNameList`;
    const params = {
      inputEntityName: inputEntityName
    }

    return this.http.get(url, { params: params })
  }

  saveSmartQueryWithAutoSave(sqID:number, daeID:number, smartQueryParam:string): Observable<any> {
    const url = `${this.BASE_URL}/saveSmartQueryWithAutoSave`
    const params = {
      sqID:sqID,
      mainID:daeID,
      isEditing: true, //todo I am not sure about that
      smartQueryParam:smartQueryParam

    }

    return this.http.post(url, {})
  }

  setSelectedReferenceEntity(referenceEntityFormSetting: any[]): void {
    this._referenceEntityFormSetting = referenceEntityFormSetting
  }

  getSelectedReferenceEntity(): any[] {
    return this._referenceEntityFormSetting
  }

  setPostFilterParam(postFilterParam: PostFilterParam): void {
    const postFilterParamCopy = { ...postFilterParam }
    this._postFilterParam = postFilterParamCopy
  }

  getPostFilterParam(): PostFilterParam {
    return this._postFilterParam
  }

  setPreFilterMainParam(preFilterMainParam: PreFilterParam): void {
    const preFilterMainParamCopy = { ...preFilterMainParam }
    this._preFilterMainParam = preFilterMainParamCopy
  }

  getPreFilterMainParam(): PreFilterParam {
    return this._preFilterMainParam
  }

  setPreFilterRefParam(preFilterRefParam: PreFilterParam[]): void {
    const preFilterMainParamCopy = { ...preFilterRefParam }
    this._preFilterRefParam = Object.assign([], preFilterMainParamCopy)
  }

  getPreFilterRefParam(): PreFilterParam[] {
    return this._preFilterRefParam
  }

  getDetailedEntityHeader(daeID: number) {
    const url = `${this.BASE_URL}/getDetailedEntityHeader`;
    const params = new HttpParams()
      .set('daeID', daeID)

    return this.http.get(url,{ params: params });
  }

  setSavedParam(savedParam: any): void {
    this._postFilterParam = savedParam.postFilterParam
    this._preFilterMainParam = savedParam.preFilterMainParam
    this._preFilterRefParam = savedParam.preFilterReferenceParamCombineList
    this._referenceEntityFormSetting = savedParam.referenceEntityFormSettingCombineList
    const savedParamCopy = { savedParam }
    this._savedParam = savedParamCopy
  }

  getSavedParam(): any {
    return this._savedParam
  }

  setMainEntityName(mainEntityName: string): void {
    this._mainEntityName = mainEntityName
  }

  getMainEntityName(): string {
    return this._mainEntityName
  }

  setMainEntityId(mainEntityId: number): void {
    this._mainEntityId = mainEntityId
  }

  getMainEntityId(): number {
    return this._mainEntityId
  }

}
