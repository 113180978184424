import { Component } from '@angular/core';

@Component({
  selector: 'app-operation-menu',
  templateUrl: './operation-menu.component.html',
  styleUrls: ['./operation-menu.component.scss']
})
export class OperationMenuComponent {

  operators = ['count', 'countIf', 'count_distinct', 'sum', 'sumIf' +
  '' ]

  currentOperator:string = ""

  setOperator(operator: string):void {
    this.currentOperator = operator
  }
}
