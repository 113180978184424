import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OperatorTransformPipe} from "../../../../../../shared/pipes/operator-transform.pipe";
import {
  EnumBooleanOperator,
  EnumBooleanViewOperator, EnumCategoricalOperator,
  EnumCategoricalViewOperator,
  EnumDataType, EnumDateOperator, EnumDateViewOperator, EnumNullOperator, EnumNullViewOperator, EnumNumOperator,
  EnumNumViewOperator, EnumStrOperator, EnumStrViewOperator
} from "../../../../../../enums/data-type.enum";
import {EnumLogicQuery} from "../../../../../../enums/logic-query.enum";
import {PreFilterParam} from "../../../../smartQuery/models/pre-filter-param";
import {SmartQueryService} from "../../../../smartQuery/_services/smart-query.service";

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.css']
})
export class FilterItemComponent implements OnInit {

  @Input() entityType: string;
  @Input() mainEntityName: string;
  @Input() mainEntityId: number;
  @Input() mainEntityList: any[];
  @Input() entityIndex: number;
  @Output() preFilterParam = new EventEmitter<PreFilterParam>()
  @ViewChild('valueInput') valueInput: ElementRef;
  constructor(private smartQueryService: SmartQueryService) { }

  ngOnInit(): void {
    const savedParam = this.smartQueryService.getSavedParam()
    if(this.entityType === "Main" && savedParam.savedParam.preFilterMainParam != null) {
      this.smartQueryList = savedParam.savedParam.preFilterMainParam.smartQueryList
      this.logicSelector = savedParam.savedParam.preFilterMainParam.logicSelector
      this.customLogic = savedParam.savedParam.preFilterMainParam.logicSequence
      if (this.smartQueryList == undefined) { this.smartQueryList = [] }
    }
    else if (this.entityType === "Reference" && savedParam.savedParam.preFilterReferenceParamCombineList && savedParam.savedParam.preFilterReferenceParamCombineList.length != 0) {
      this.smartQueryList = savedParam.savedParam.preFilterReferenceParamCombineList[this.entityIndex].smartQueryList
      this.logicSelector = savedParam.savedParam.preFilterReferenceParamCombineList[this.entityIndex].logicSelector
      this.customLogic = savedParam.savedParam.preFilterReferenceParamCombineList[this.entityIndex].logicSequence
      if (this.smartQueryList == undefined) { this.smartQueryList = [] }
    }

  }

  // Pipe: used to transform the operator to "mongo operator"
  operatorTransform = new OperatorTransformPipe()
  enumDataType = EnumDataType

  // Use to build preFilterParam
  selectedEntity: any = null
  selectedOperator: string = null
  selectedValue: {num: string, unit: string} = {num: '', unit: ''}
  operatorOptions: string[];

  smartQueryList: any[] = []

  logicSelector: string = "ALL_AND"
  customLogic: string = null;


  // Operator for different data type
  booleanOperatorList: any[] = Object.values(EnumBooleanOperator);
  booleanOperatorViewList: any[] = Object.values(EnumBooleanViewOperator);
  numOperatorList: any[] = Object.values(EnumNumOperator);
  numOperatorViewList: any[] = Object.values(EnumNumViewOperator);
  nullOperatorList: any[] = Object.values(EnumNullOperator);
  nullOperatorViewList: any[] = Object.values(EnumNullViewOperator);
  categoricalOperatorList: any[] = Object.values(EnumCategoricalOperator);
  categoricalOperatorViewList: any[] = Object.values(EnumCategoricalViewOperator);
  strOperatorList: any[] = Object.values(EnumStrOperator);
  strOperatorViewList: any[] = Object.values(EnumStrViewOperator);
  dateOperatorList: any[] = Object.values(EnumDateOperator);
  dateOperatorViewList: any[] = Object.values(EnumDateViewOperator);

  get enumLogicQuery(): typeof EnumLogicQuery { return EnumLogicQuery; }

  outputParam: PreFilterParam = {
    daeID: '',
    customField: [],
    logicSelector: this.logicSelector,
    smartQueryList: this.smartQueryList,
    logicSequence: this.customLogic
  }



  /***
   * Use field name to generate operator's option list
   * @param fieldName
   */
  onSelectTargetField(fieldName: string, index: number): void {

    // Use selected field name to obtain the field
    this.selectedEntity = this.mainEntityList.find(entity => entity.fieldName === fieldName)

    // Options for operator: dependends on the dataType of the selected field
    switch (this.selectedEntity.dataType) {
      case EnumDataType.Int:
      case EnumDataType.Long:
      case EnumDataType.Double:
      case EnumDataType.Float:
        this.operatorOptions = Object.values(EnumNumViewOperator)
        break;
      case EnumDataType.Boolean:
        this.operatorOptions = Object.values(EnumBooleanViewOperator)
        break;

      // TODO: is / is not null should be contained in string, date type.. need further test
      case EnumDataType.Categorical:
        this.operatorOptions = [...Object.values(EnumCategoricalViewOperator), ...Object.values(EnumNullViewOperator)]
        break;
      case EnumDataType.String:
        this.operatorOptions = [...Object.values(EnumStrViewOperator), ...Object.values(EnumNullViewOperator)]
        break;
      case EnumDataType.DateDate:
      case EnumDataType.DateDatetime:
      case EnumDataType.DateTimestamp:
        this.operatorOptions = [...Object.values(EnumDateViewOperator), ...Object.values(EnumNullViewOperator)]
        break;
    }

    // this.daeID = this.mainEntityList.find(entity => entity.fieldName === fieldName)
    this.smartQueryList[index].field = this.mainEntityList.find(entity => entity.fieldName === fieldName).fieldName;
    this.smartQueryList[index].dataType = this.mainEntityList.find(entity => entity.fieldName === fieldName).dataType;
  }

  /***
   * Set the operator for the preFilter
   * @param operator
   */
  onSelectOperator(operator: string, index: number) {
    // transform to "mongo" format (>= --> $gte)
    this.selectedOperator = this.operatorTransform.transform(operator);

    this.smartQueryList[index].operator = this.operatorTransform.transform(operator);
  }

  /***
   * Select the value for the preFilter
   * @param value
   */
  onSelectValue(value: {num: string, unit: string}) {
    this.selectedValue.num = value.num;
    this.selectedValue.unit = value.unit
  }

  /***
   * Select with Date Type
   * @param unit
   * @param index
   */
  onSelectDateMode(unit: string, index: number) {
    this.smartQueryList[index].dateMode = unit
  }

  /***
   * Select the Logic
   * @param logic
   */
  onSelectLogic(logic: string) {
    this.logicSelector = logic;
  }

  /***
   * Edit the custom logic
   * @param value
   */
  onSelectCustomLogic(logic: string) {
    this.customLogic = logic;
  }

  /***
   * Trigger when clicking "+Create"
   */
  addSmartQueryLogic() {
    let length = this.smartQueryList.length;
    this.smartQueryList = this.smartQueryList.slice()
    this.smartQueryList[length] = {
      field: '',
      operator: '',
      value: {},
      dataType: '',
      dateMode: '',
      isNull: false,
      aggOperator: ''
    }
  }

  /***
   * Delete item from smart query list
   * @param index
   */
  deleteSmartQueryLogic(index) {
    this.smartQueryList.splice(index, 1);

    this.onPreFilterParamChanged(this.smartQueryList)
    // this.outputParam = this.smartQueryList.length === 0 ? null : this.outputParam
  }

  /***
   * Edit param
   * @param smartQueryList
   */
  onPreFilterParamChanged(smartQueryList: any[]) {
    this.outputParam.daeID = this.mainEntityId
    this.outputParam.logicSelector = this.logicSelector
    this.outputParam.smartQueryList = smartQueryList
    this.outputParam.logicSequence = this.customLogic
    this.preFilterParam.emit(this.outputParam);
  }
}
