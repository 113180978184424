import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

export const routes: Routes = [
  {path: "auth", loadChildren: () => import('./theme/pages/auth/auth.module').then(m => m.AuthModule)},
  {path: "documentation/:type", loadChildren: () => import('./theme/pages/documentation/documentation.module').then(m => m.DocumentationModule)},
  { path: 'error', loadChildren: () => import('./theme/pages/errors/errors.module').then(m => m.ErrorsModule) },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected', })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
