
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationExtras } from "@angular/router";

@Injectable()
export class RouteService {


    constructor(private router: Router) {
    }

    navigateTo(commands: any[], extras?: NavigationExtras) {
        this.router.navigate(commands, extras);
    }
}
