<button [matMenuTriggerFor]="menu" mat-button>
  <i class="fa-solid fa-caret-down" style="font-size: 25px"></i>
</button>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let title of menuRelatedTableTitles; let index = index ">
    <ng-container
      *ngTemplateOutlet="tmpl; context: {title: title, subtitles: subtitles, index:index}">
    </ng-container>
  </ng-container>

</mat-menu>

<ng-template #tmpl let-index="index" let-subtitles="subtitles" let-title="title">
  <button
    (click)="changeSubtitle(index)"
    [matMenuTriggerFor]="levelTwoItem"
    mat-menu-item>
    {{title}}
  </button>
</ng-template>

<mat-menu #levelTwoItem="matMenu">
  <button (click)="showMenuClick(subtitle)" *ngFor="let subtitle of subTitleSelected"
          mat-menu-item>
    {{subtitle}}
  </button>
</mat-menu>

<!--<mat-menu #displayedTitle="matMenu"></mat-menu>-->

<!--  <div style="background-color: grey" >-&#45;&#45;Other functions&#45;&#45;&#45;&#45;</div>-->
<!--  <button mat-menu-item (click)="openDeriveNewFieldModal()" class="font-weight-bold">-->
<!--  Derive a new field-->
<!--  </button>-->
<!--  <button mat-menu-item >-->
<!--    <div class="font-weight-bold">Remove this field</div>-->
<!--  </button>-->
<!--  <button mat-menu-item >-->
<!--    <div class="font-weight-bold">Derive a new field</div>-->
<!--  </button>-->
<!--  <button mat-menu-item >-->
<!--    <div class="font-weight-bold">Fitler (...)</div>-->
<!--  </button>-->
