<div class="form-group row">
  <div class="col-lg-12">
    <h4 class="modal-title" style="margin: 10px">Input Filter</h4>
    <app-filter-item
      [entityType]="'Main'"
      [entityIndex]="-1"
      [mainEntityName]="mainEntity.name"
      [mainEntityId]="mainEntity.id"
      [mainEntityList]="mainEntityList"
      (preFilterParam)="onPreFilterParamChanged($event, 'main', -1)"
    >
    </app-filter-item>

    <div *ngFor="let refEntity of selectedRefEntityList; index as i">
      <app-filter-item
        [entityIndex]="i"
        [entityType]="'Reference'"
        [mainEntityName]="refEntity.daeName"
        [mainEntityList]="refEntity.dataFields"
        [mainEntityId]="refEntity.daeID"
        (preFilterParam)="onPreFilterParamChanged($event, 'ref', i)"
        >

      </app-filter-item>
    </div>

    <div class="modal-footer">
      <button (click)="closeModalWithoutSave()" class="btn btn-outline-dark" type="button">Back</button>
      <button (click)="closeModal()" class="btn xx btn-danger xx btn-elevate" type="button">Save</button>
    </div>
  </div>
</div>
