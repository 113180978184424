import {HttpClient} from '@angular/common/http';
import {Component, ViewChild} from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';
import {CellClickedEvent, ColDef, GridReadyEvent, GridApi} from 'ag-grid-community';
import {Observable} from 'rxjs';
import transactionSampleData from './mock-data/customerAndTransaction/transactionSampleData.json'
import customerRecordsMinified from './mock-data/customerAndTransaction/customer_records_minified.json'
import {CustomHeaderComponent} from "./custom-header/custom-header.component";
import constructionEmployeeInfo from "./mock-data/employee/construction_employee_info.json"
import customerRefundRecord from "./mock-data/customerAndTransaction/customer_refund_record.json"
import rebursementRecord from "./mock-data/employee/rebursement_record.json"
import sickEmployeeRecord from "./mock-data/sickEmployee/sickEmployeeRecord.json"
import drugTakenBySickEmployee from "./mock-data/sickEmployee/drugTakenBySickEmployee.json"
import {MatSelect} from "@angular/material/select";
import {MatOption} from "@angular/material/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {InputFilterModalComponent} from "./modals/input-filter-modal/input-filter-modal.component";

interface MyObjectType {
  [key: string]: unknown;
}
interface GridContext {
  componentParent: any,
  relatedTables: MyObjectType
}

interface IncomingData{
  mainTable: MyObjectType[],
  relatedTables: MyObjectType
}

@Component({
  selector: 'app-smart-query-v2',
  templateUrl: './smart-query-v2.component.html',
  styleUrls: ['./smart-query-v2.component.scss']
})

export class SmartQueryV2Component {
  constructor(private http: HttpClient,
              public modalService: NgbModal) {}

  gridApi!: GridApi;
  context:GridContext = {componentParent: this, relatedTables: {hello:'world'}}
  gridTitle:string = ""
  rowDataInstant: any;
  selectFieldModal:any;
  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    editable:true,
    autoHeaderHeight:true //todo why the fuck this is not working
  };

  components: { [p: string]: any; } = { agColumnHeader: CustomHeaderComponent };
  columnDefs: ColDef[] = []
  rowData$!: Observable<any[]>;

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  mainAndRelatedTableArray:IncomingData[] = [
    {mainTable: customerRecordsMinified,
      relatedTables:{
        transactionSampleData: transactionSampleData,
        customerRefundRecord:customerRefundRecord
      }
    },

    {mainTable: constructionEmployeeInfo,
      relatedTables:
        {
          rebursementRecord: rebursementRecord
        }
    },
    {mainTable: sickEmployeeRecord,
      relatedTables:
        {
          drugTakenBySickEmployee:drugTakenBySickEmployee
        }
    },
  ]

  onGridReady(params: GridReadyEvent) {
    this.loadData(this.mainAndRelatedTableArray[0].mainTable)
    this.loadRelatedTable(0)
  }

  loadRelatedTable(index:number):void {
    this.context.relatedTables = this.mainAndRelatedTableArray[index].relatedTables //why this is not having error?
    console.log('typeof this.context.relatedTables', typeof this.context.relatedTables)
    console.log("this.context.relatedTables: ", this.context.relatedTables)
    //todo this part have problem, it  gives an [ { rebursementReocrd :[...] }]
    //todo correct result should be like {transactionSampleData: Array(28858), customerRefundRecord: Array(1000)}
  }


  loadData(data:any[]):void{
    this.rowDataInstant = data
    this.columnDefs = this.columnRefGenerator(this.rowDataInstant[0])
  }

  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }

  clearSelection(): void {
    this.agGrid.api.copySelectedRangeDown()
  }

  columnRefGenerator(firstObjectInArray:any):ColDef[]{
    const columnDefs:ColDef[] = []
    for (const columnTitle in firstObjectInArray){
      columnDefs.push({field:columnTitle})
    }
    columnDefs.push({field:"Select field", width:220, cellStyle: {color: 'red', 'background-color': 'rgb(204, 255, 255)'},headerClass: 'custom-header' })
    return columnDefs
  }

  showUser() {
    this.loadData(this.mainAndRelatedTableArray[0].mainTable)
    this.loadRelatedTable(0)
  }

  showEmployeeInfo() {
    this.loadData(this.mainAndRelatedTableArray[1].mainTable)
    this.loadRelatedTable(1)
  }

  showSickEmployee() {
    this.loadData(this.mainAndRelatedTableArray[2].mainTable)
    this.loadRelatedTable(2)
  }

  parentMethod(coordinate :any):void {
    this.gridTitle = `y-coordinate of button ${coordinate}`
    this.selectFieldModal = {
      'background-color': 'white',
      'top':`${coordinate.top}px`,
      'left':`${coordinate.left}px`,
      'position':'relative',
      "z-index":"1050",
      "width":"200px",
      "height":"200px",
      "visibility": "visible"
    };
  }

  sayScrolling() {
    console.log('scrolling')
  }

  logGridApi() {
    console.log('%cBelow is gridApi: ', 'color: brown;font-weight: bold')
    console.info(this.gridApi)
  }

  logAgGrid() {
    console.log('%cBelow is agGrid: ', 'color: blue;font-weight: bold')
    console.info(this.agGrid)
  }

  logAgGridApi() {
    console.log('%cBelow is agGrid.api: ', 'color: green;font-weight: bold  ')
  }

  setHeaderHeight() {
    this.agGrid.api.setHeaderHeight(300)
  }

  Refresh() {}

  inputFilter() {
    const modalRef = this.modalService.open(InputFilterModalComponent, {size: ''});

  }

  outputFilter() {
    const modalRef = this.modalService.open(InputFilterModalComponent, {size: ''});
  }

  // ================================================================================

  @ViewChild('select') select: any

  allSelected=false;
  foods: any[] = [
    {value: 'steak-0', viewValue: 'User_ID'},
    {value: 'pizza-1', viewValue: 'Contact_Number'},
    {value: 'tacos-2', viewValue: 'Last_Name'},
    {value: 'tacos-3', viewValue: 'First_Name'},
    {value: 'tacos-4', viewValue: 'Address'},
    {value: 'tacos-5', viewValue: 'Last_Modified_Date'},
  ];
  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  // ================================================================================

}
