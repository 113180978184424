<button  [matMenuTriggerFor]="menu">Operators</button>
<mat-menu #menu="matMenu">
  <ng-container></ng-container>
  <button mat-menu-item (click)="setOperator(operator)"
          *ngFor="let operator of operators"
  >{{operator}}
  </button>

</mat-menu>
<ng-container *ngIf="currentOperator">
  : {{currentOperator}}
  <i class="fa-sharp fa-solid fa-xmark"
     (click)="setOperator('')" >
  </i>
</ng-container>
