import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {
  EnumCustomFieldBooleanOperator,
  EnumCustomFieldComparisonOperator,
  EnumCustomFieldConditionOperator,
  EnumCustomFieldConstantOperator, EnumCustomFieldDateOperator,
  EnumCustomFieldExpressType,
  EnumCustomFieldNumericalOperator, EnumCustomFieldStrOperator, EnumCustomFieldTypeOperator
} from "../../../../../enums/data-type.enum";


@Component({
  selector: 'app-derive-new-field-modal',
  templateUrl: './derive-new-field-modal.component.html',
  styleUrls: ['./derive-new-field-modal.component.scss']
})
export class DeriveNewFieldModalComponent {

  expressionList: string[] = Object.values(EnumCustomFieldExpressType);

  operatorList: string[] = []

  selected: string = ''

  operator: string = ''

  newFieldDataType: string[] = []

  onSelectExpression(value: string): void {
    this.selected = value
    this.operatorList = [""]
    switch (value) {
      case EnumCustomFieldExpressType.Arithmetic:
        this.operatorList.push(...Object.values(EnumCustomFieldNumericalOperator))
        break
      case EnumCustomFieldExpressType.Boolean:
        this.operatorList.push(...Object.values(EnumCustomFieldBooleanOperator))
        break
      case EnumCustomFieldExpressType.Comparison:
        this.operatorList.push(...Object.values(EnumCustomFieldComparisonOperator))
        break
      case EnumCustomFieldExpressType.Condition:
        this.operatorList.push(...Object.values(EnumCustomFieldConditionOperator))
        break
      case EnumCustomFieldExpressType.Constant:
        this.operatorList.push(...Object.values(EnumCustomFieldConstantOperator))
        break
      case EnumCustomFieldExpressType.Date:
        this.operatorList.push(...Object.values(EnumCustomFieldDateOperator))
        break
      case EnumCustomFieldExpressType.String:
        this.operatorList.push(...Object.values(EnumCustomFieldStrOperator))
        break
      case EnumCustomFieldExpressType.Type:
        this.operatorList.push(...Object.values(EnumCustomFieldTypeOperator))
        break

    }
  }

  onSelectOperator(operator: string): void {
    this.operator = operator
    this.newFieldDataType = [""]

  }

  constructor(public activeModal: NgbActiveModal) {
  }

}
