<div class="modal-header">
  <h4 class="modal-title">Derived a New Field</h4>
  <!--  <button type="button" class="close" aria-label="Close"  (click)="activeModal.close('Close click')")  >-->
  <!--    <span aria-hidden="true">&times;</span>-->
  <!--  </button>-->
</div>
<div class="modal-body  ">
  <div class="p-3">
    <span>New Field Name:  </span>
    <input
      matInput
      placeholder="custom field name">
<!--  <select name="cars">-->
<!--    <option value="volvo">Transction</option>-->
<!--  </select>-->
  </div>
  <div class="p-3">
    <span>Expression Type: </span>
    <span>
  <select name="expression" #expressionOption (change)="onSelectExpression(expressionOption.value)">
<!--    <option value="volvo">conditonal</option>-->
    <option value="" disabled>--- Please Select ---</option>
    <option *ngFor="let expression of expressionList" [value]="expression">
          {{expression}}
    </option>
  </select>
    </span>
  </div>
  <div class="p-3">
    <span class="p-3">Operator: </span>
    <span>
  <select name="operator" #operatorOption (change)="onSelectOperator(operatorOption.value)">
<!--    <option value="volvo">sumIf</option>-->
    <option value="" disabled>--- Please Select ---</option>
    <option *ngFor="let operation of operatorList" [value]="operation">
          {{operation}}
    </option>
  </select>
    </span>
  </div>
  <div class="p-3">
    <span>New Field Data Type: </span>
    <span>
      <select name="cars">
        <option value="volvo">String</option>
      </select>
    </span>
  </div>
  <div class="p-3">
    <span class="p-3">    <button >Add Select</button>
</span>
    <span class="p-3">    <button>Add Input</button>
</span>


  </div>
  <div class="p-3">
    <span>Argument 1:  </span>
    <span>
  <select name="cars">
    <option value="volvo">Transaction</option>
  </select>
    </span>
  </div>
  <div class="p-3">
    <span>Argument 2:  </span>
    <span>
  <select name="cars">
    <option value="volvo">Transction</option>
  </select>
    </span>
  </div>

</div>


<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" class="btn btn-outline-dark" type="button">Save</button>
</div>
