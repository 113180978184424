import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({
  providedIn: "root"
})

@Pipe({
  name: 'operatorTransform'
})
export class OperatorTransformPipe implements PipeTransform {

  transform(operator: string): string {
    switch (operator) {
      // Numeric data type
      case '>=':
        return '$gte';
      case '>':
        return '$gt';
      case '<=':
        return '$lte';
      case '<':
        return '$lt';
      case '==':
        return '$eq';
      case '!=':
        return '$ne';

      //String data type
      case 'equal':
        return '$eq';
      case 'not equal':
        return '$ne'
      case 'start with':
        return '$startWith';
      case 'end with':
        return '$endWith';
      case 'contains':
        return '$contains';
      case 'not contains':
        return '$notContains';
      case 'pattern match':
        return '$patternMatch';
      case 'in':
        return '$in';
      case 'not in':
        return '$nin';
      case 'is':
        return '$isNull';
      case 'is not':
        return '$notNull';
      default:
        return operator;
    }
  }
}
