<div class="col-lg-12">
  <h4>{{entityType}} Entity:  {{mainEntityName}}</h4>
  <div class="col-md-6 text-md-end mt-3">
    <a title="Add Logic" class="btn  btn-danger btn-sm mb-3"
       (click)="addSmartQueryLogic();"
    >
      + Create
    </a>
  </div>
  <table class="table table-head-custom table-vertical-center">
    <thead>
    <tr>
      <th>No.</th>
      <th>Field</th>
      <th>Operator</th>
      <th>Value</th>
      <th>Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="smartQueryList.length === 0">
      <td class="text-align-center border" colspan="8">
        No query available in table
      </td>
    </tr>

    <tr *ngFor="let item of smartQueryList; index as i">
      <td>{{i + 1}}</td>

      <!-- #########  -->
      <!-- field  -->
      <!-- #########  -->
      <td>
        <select title class="form-control" [(ngModel)]="item.field" name="field"
                (change)="onSelectTargetField(item.field, i);onPreFilterParamChanged(this.smartQueryList)"
        >
          <option *ngFor="let mainEntityField of mainEntityList" [value]="mainEntityField.fieldName">
            {{mainEntityField.fieldName}}
          </option>
        </select>
      </td>
      <!-- #########  -->
      <!-- field  -->
      <!-- #########  -->

      <!-- #########  -->
      <!-- operator   -->
      <!-- #########  -->
      <td [ngSwitch]="item.dataType">
        <!-- Integer  -->
        <select title *ngSwitchCase=enumDataType.Int [(ngModel)]="item.operator" name="operator"
                class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of numOperatorList.concat(nullOperatorList); index as j" [value]="operator">
            {{numOperatorViewList.concat(nullOperatorViewList)[j]}}
          </option>
        </select>
        <!-- Double  -->
        <select title *ngSwitchCase=enumDataType.Double [(ngModel)]="item.operator" name="operator"
                class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of numOperatorList.concat(nullOperatorList); index as j" [value]="operator">
            {{numOperatorViewList.concat(nullOperatorViewList)[j]}}
          </option>
        </select>
        <!-- Long  -->
        <select title *ngSwitchCase=enumDataType.Long [(ngModel)]="item.operator" name="operator"
                class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of numOperatorList.concat(nullOperatorList); index as j" [value]="operator">
            {{numOperatorViewList.concat(nullOperatorViewList)[j]}}
          </option>
        </select>
        <!-- Float  -->
        <select title *ngSwitchCase=enumDataType.Float [(ngModel)]="item.operator" name="operator"
                class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of numOperatorList.concat(nullOperatorList); index as j" [value]="operator">
            {{numOperatorViewList.concat(nullOperatorViewList)[j]}}
          </option>
        </select>
        <!-- Boolean  -->
        <select title *ngSwitchCase=enumDataType.Boolean [(ngModel)]="item.operator" name="operator"
                class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of booleanOperatorList.concat(nullOperatorList); index as j" [value]="operator">
            {{booleanOperatorViewList.concat(nullOperatorViewList)[j]}}
          </option>
        </select>
        <!-- datetime  -->
        <select title *ngSwitchCase=enumDataType.DateDatetime [(ngModel)]="item.operator" name="operator"
                class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of numOperatorList.concat(nullOperatorList).concat(dateOperatorList); index as j"
                  [value]="operator">
            {{numOperatorViewList.concat(nullOperatorViewList).concat(dateOperatorViewList)[j]}}
          </option>
        </select>
        <!-- date -->
        <select title *ngSwitchCase="enumDataType.DateDate" [(ngModel)]="item.operator" name="operator"
                class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of numOperatorList.concat(nullOperatorList).concat(dateOperatorList); index as j"
                  [value]="operator">
            {{numOperatorViewList.concat(nullOperatorViewList).concat(dateOperatorViewList)[j]}}
          </option>
        </select>
        <!-- timestamp  -->
        <select title *ngSwitchCase=enumDataType.DateTimestamp [(ngModel)]="item.operator" name="operator"
                class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of numOperatorList.concat(nullOperatorList).concat(dateOperatorList); index as j"
                  [value]="operator">
            {{numOperatorViewList.concat(nullOperatorViewList).concat(dateOperatorViewList)[j]}}
          </option>
        </select>
        <!-- Categorical  -->
        <select title *ngSwitchCase=enumDataType.Categorical [(ngModel)]="item.operator" name="operator" class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of categoricalOperatorList.concat(nullOperatorList); index as j" [value]="operator">
            {{categoricalOperatorViewList.concat(nullOperatorViewList)[j]}}
          </option>
        </select>
        <!-- default  -->
        <select title *ngSwitchDefault [(ngModel)]="item.operator" name="operator" class="form-control"
                (ngModelChange)="onSelectOperator(item.operator, i);onPreFilterParamChanged(this.smartQueryList)">
          <option *ngFor="let operator of strOperatorList.concat(nullOperatorList); index as j" [value]="operator">
            {{strOperatorViewList.concat(nullOperatorViewList)[j]}}
          </option>
        </select>
      </td>

      <!-- #########  -->
      <!-- value   -->
      <!-- #########  -->
      <td>
        <div *ngIf="item.operator != '$isNull' && item.operator != '$notNull' && item.dataType != 'DATE.DATE'">
          <input
            title
            type="text"
            class="form-control"
            name="value"
            placeholder="value"
            (change)="onSelectValue(item.value);onPreFilterParamChanged(this.smartQueryList)"
            [(ngModel)]="item.value.num"

            style="width: 400px"
          />
        </div>

        <div *ngIf="item.operator == '$isNull' || item.operator == '$notNull'">
          <select title class="form-control" [(ngModel)]="item.value.num" name="value"
                  (change)="onSelectValue(item.value)"
                  style="width: 400px"
          >
            <option value="null">null</option>
          </select>
        </div>

        <div *ngIf="item.operator != '$isNull' && item.operator != '$notNull' && item.dataType == 'DATE.DATE' && item.operator != 'LAST_DATE'" style="display: flex">
          <select title class="form-control" [(ngModel)]="item.value.unit" name="value"
                  (change)="onSelectDateMode(item.value.unit, i);onPreFilterParamChanged(this.smartQueryList)"
                  style="width: 200px "
          >
            <option value="YEAR OF">Year Of</option>
            <option value="MONTH OF">Month Of</option>
            <option value="DAY OF">Day Of</option>
          </select>

          <input
            title
            type="text"
            class="form-control"
            name="value"
            placeholder="value"
            (change)="onSelectValue(item.value);onPreFilterParamChanged(this.smartQueryList)"
            [(ngModel)]="item.value.num"
            style="width: 200px"
          />

        </div>

        <div *ngIf="item.operator != '$isNull' && item.operator != '$notNull' && item.dataType == 'DATE.DATE' && item.operator == 'LAST_DATE'" style="display: flex">
          <input
            title
            type="text"
            class="form-control"
            name="value"
            placeholder="value"
            (change)="onSelectValue(item.value);onPreFilterParamChanged(this.smartQueryList)"
            [(ngModel)]="item.value.num"
            style="width: 200px"
          />

          <select title class="form-control" [(ngModel)]="item.value.unit" name="value"
                  (change)="onSelectDateMode(item.value.unit, i);onPreFilterParamChanged(this.smartQueryList)"
                  style="width: 200px "
          >
            <option value="YEAR OF">Year</option>
            <option value="MONTH OF">Month</option>
            <option value="DAY OF">Day</option>
          </select>
        </div>

      </td>
      <!-- #########  -->
      <!-- value   -->
      <!-- #########  -->

      <td>
        <a
          title="Delete Pre Filter"
          class="btn btn-icon btn-light xx btn-hover-secondary btn-sm mx-3"
          (click)="deleteSmartQueryLogic(i)"
        >
<!--          <span-->
<!--            [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"-->
<!--            cacheSVG="true"-->
<!--            class="svg-icon svg-icon-md secondary"-->
<!--          >-->
<!--          </span>-->
          <i class="fas fa-trash"></i>
        </a>
      </td>
    </tr>
    </tbody>
  </table>

  <!-- #########  -->
  <!-- Logic   -->
  <!-- #########  -->
  <div class="form-group row" style="margin-bottom: 60px;">
    <div class="col-lg-2">
      <p>Logic: </p>
    </div>
    <div class="col-lg-3">
      <select title class="form-control" [(ngModel)]="logicSelector" name="filterLogic"
              (change)="onSelectLogic(logicSelector);onPreFilterParamChanged(this.smartQueryList)"
      >
        <option value="{{enumLogicQuery.LogicQueryAllAnd}}">All AND</option>
        <option value="{{enumLogicQuery.LogicQueryAllOr}}">ALL OR</option>
        <option value="{{enumLogicQuery.LogicQueryAllCustomLogic}}">CUSTOM</option>
      </select>
    </div>

    <ng-container *ngIf="logicSelector === enumLogicQuery.LogicQueryAllCustomLogic">
      <div style="display: flex; flex-direction: column;">
      <input
        title
        type="text"
        class="form-control p-2"
        name="value"
        placeholder="value"
        [(ngModel)]="customLogic"
        value="{{customLogic}}"
        style="width: 400px"
        (change)="onSelectCustomLogic(customLogic);onPreFilterParamChanged(this.smartQueryList)"
      />
      <span style="color: gray; margin-bottom: 10px">Example: (1 AND 2) OR 3</span>
      </div>
    </ng-container>
    <!-- #########  -->
    <!-- Logic   -->
    <!-- #########  -->
  </div>


</div>
