<div class="card-custom-fit">
  <div class="card gutter-b">
    <div class="card-body">

      <div>

        <mat-form-field appearance="fill">
          <mat-label>Data Entity</mat-label>
          <mat-select>
            <mat-option
              [value]="'customerRecordsMinified'"
              (click)="showUser()"
            >
              {{"customerRecordsMinified"}}
            </mat-option>
            <mat-option
              (click)="showEmployeeInfo()"
              [value]="'constructionEmployeeInfo'"
            >
              {{"constructionEmployeeInfo"}}
            </mat-option>
            <mat-option
              (click)="showSickEmployee()"
              [value]="'sickEmployeeRecord'"
            >
              {{"sickEmployeeRecord"}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div style="float:right;">
          <button mat-raised-button (click)="Refresh()">Refresh</button>
          <button mat-raised-button (click)="inputFilter()">Input Filter</button>
          <button mat-raised-button (click)="outputFilter()">Output Filter</button>
          <mat-form-field>
            <mat-label>Set displaying columns</mat-label>
            <mat-select  #select multiple>
              <div class="select-all">
                <mat-checkbox [(ngModel)]="allSelected"
                              [ngModelOptions]="{standalone: true}"
                              (change)="toggleAllSelection()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClick()" *ngFor="let food of foods" [value]="food.value">
                {{food.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <ag-grid-angular
      style="width: 100%; height: 5000px" id="agGrid"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="rowDataInstant"
      [rowSelection]="'multiple'"
      [animateRows]="true"
      [context]="context"
      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
      [components]="components"
    ></ag-grid-angular>
    </div>
  </div>
</div>
