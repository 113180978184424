<div style="display: flex; flex-direction:column; padding: 10px ">
  <div>
  <div
    #menuButton
    class="customHeaderMenuButton"
    (click)="onMenuClicked()"
  >
    <i class="fa-solid fa-filter"></i>
  </div>
  <div class="customHeaderLabel">{{ params.displayName }}</div>
  <div
    *ngIf="params.enableSorting"
    (click)="onSortRequested('asc', $event)"
    [ngClass]="ascSort"
    class="customSortDownLabel"
  >
    <i class="fa fa-long-arrow-alt-down"></i>
  </div>
  <div
    *ngIf="params.enableSorting"
    (click)="onSortRequested('desc', $event)"
    [ngClass]="descSort"
    class="customSortUpLabel"
  >
    <i class="fa fa-long-arrow-alt-up"></i>
  </div>
  <div
    *ngIf="params.enableSorting"
    (click)="onSortRequested(null, $event)"
    [ngClass]="noSort"
    class="customSortRemoveLabel"
  >
    <i class="fa fa-times"></i>
  </div>
  <app-nested-menu *ngIf="params.displayName==='SELECT FIELD'"
    [data] = "params.context.relatedTables"
    (selectedSubtitleEvent) = "subtitleEventTransporter($event)"
  ></app-nested-menu>
  </div>
  <app-operation-menu *ngIf="params.displayName==='SELECT FIELD'"></app-operation-menu>
</div>
