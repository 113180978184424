import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ThemeComponent} from './theme/theme.component';
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {SplashScreenModule} from './shared/components/splash-screen/splash-screen.module';
// #fake-start#
import {ToastrService} from "./global-services/toastr.service";
import {RouteParamsService} from "./global-services/route-params.service";
import {GlobalHttpInterceptorProvider} from "./global-services/global-http-interceptor.service";
import {ScriptLoaderService} from "./global-services/script-loader.service";
import {SweetAlertService} from "./global-services/sweet-alert.service";
import {BreadCrumbService} from "./global-services/bread-crumb.service";
import {CanDeactivateService} from "./global-services/can-deactivate.service";
import {DataTableAjaxService} from "./global-services/data-table-ajax.service";
import {RouteService} from "./global-services/route.service";
import {ThemeRoutingModule} from "./theme/theme-routing.module";
import {PendingChangesGuard} from "./global-services/can-deactivate-guard.service";
import {ContextDataResolver} from "./theme/context-data.resolver";
import {GlobalErrorHandler} from "./global-services/error-handler.service";
import {SplashRoutingModules} from "./shared/components/splash-routing/splash-routing.modules";
import {LayoutModule} from "./theme/_layout/layout.module";
import {AgGridModule} from "ag-grid-angular";
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {SmartQueryV2Module} from "./theme/pages/smart-query-v2/smart-query-v2.module";
import { SmartQueryV3Component } from './theme/pages/smart-query-v3/smart-query-v3.component';
import {SmartQueryV3Module} from "./theme/pages/smart-query-v3/smart-query-v3.module";
import {EnumToArrayOfObjectValuePipe} from "./shared/pipes/enum-to-array-of-object-value.pipe";
import {MatNativeDateModule} from "@angular/material/core";
import { MatDialogModule } from '@angular/material/dialog';


// import { NbThemeModule, NbLayoutModule } from '@nebular/theme';
// import { NbEvaIconsModule } from '@nebular/eva-icons';



@NgModule({
  declarations: [AppComponent, ThemeComponent,  ], //Declares which belong to this module
  imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SplashScreenModule,
        SplashRoutingModules,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        LayoutModule,
        ThemeRoutingModule,
        AgGridModule,
        MatMenuModule,
        MatSlideToggleModule,
    MatNativeDateModule,
    MatDialogModule,


    // NbThemeModule.forRoot({ name: 'default' }),
    // NbLayoutModule,
    // NbEvaIconsModule,
  ],
  providers: [
    ScriptLoaderService,
    ToastrService,
    GlobalHttpInterceptorProvider,
    SweetAlertService,
    BreadCrumbService,
    CanDeactivateService,
    RouteParamsService,
    DataTableAjaxService,
    RouteService,
    PendingChangesGuard,
    ContextDataResolver,
    GlobalErrorHandler,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: {
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
