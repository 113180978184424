import { Injectable } from "@angular/core";
declare var toastr: any;

@Injectable()
export class ToastrService {

    private options: any = {
        'closeButton': true,
        'timeOut': 3000,
        'extendedTimeOut': 0,
        'tapToDismiss': false,
        'showDuration': 500,
        'hideDuration': 500
    };

    constructor() {
    }


    success(message: string) {
        toastr.options = this.options;
        toastr['success'](message, 'Success');
    }


    error(message: string) {
        toastr.options = this.options;
        toastr['error'](message, 'Error');
    }


    info(message: string) {
        toastr.options = this.options;
        toastr['info'](message, 'Info');
    }


    warning(message: string) {
        toastr.options = this.options;
        toastr['warning'](message, 'Warning');
    }


    readonly(message: string) {
        let options: any = {
            'closeButton': true,
            'timeOut': 20000,
            'extendedTimeOut': 0,
            'tapToDismiss': false,
            'showDuration': 500,
            'hideDuration': 500
        };

        toastr.options = options;
        toastr['warning'](message, 'Warning');
    }
}
