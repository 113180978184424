import {ChangeDetectionStrategy, Component, OnInit,} from '@angular/core';
// import { TranslationService } from './modules/i18n/translation.service';
// language list
// import { locale as enLang } from './modules/i18n/vocabs/en';
// import { locale as chLang } from './modules/i18n/vocabs/ch';
// import { locale as esLang } from './modules/i18n/vocabs/es';
// import { locale as jpLang } from './modules/i18n/vocabs/jp';
// import { locale as deLang } from './modules/i18n/vocabs/de';
// import { locale as frLang } from './modules/i18n/vocabs/fr';
import {SplashScreenService} from './shared/components/splash-screen/splash-screen.service';
import {Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {SplashRoutingService} from "./shared/components/splash-routing/splash-routing.service";

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit{
  constructor(
    // private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private splashRoutingService: SplashRoutingService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.routerSubscribeEvent();
    // register translations
    // this.translationService.loadTranslations(
    //   enLang,
    //   chLang,
    //   // esLang,
    //   // jpLang,
    //   // deLang,
    //   // frLang
    // );
  }

  routerSubscribeEvent() {
    this.router.events.subscribe((event: Event) => {  //fat arrow. anonymous function
      switch (true) {
        case event instanceof NavigationStart:
          this.splashRoutingService.showSplashRouting(true); // 採用RSJS Subject 與 Observable。 在SplashRoutingComponent監聽變量
          break;
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          this.splashRoutingService.showSplashRouting(false);
          this.splashScreenService.hide();

          // scroll to top on every route change
          window.scrollTo(0, 0);

          // to display back the body content
          setTimeout(() => {
            document.body.classList.add('page-loaded');
          }, 500);

          break;
        default:
          break;
      }
    });
  }
}
