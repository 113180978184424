import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import {ColDef, IHeaderParams} from 'ag-grid-community';


export interface ICustomHeaderParams {
  menuIcon: string;
}

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss']
})
export class CustomHeaderComponent implements IHeaderAngularComp, OnInit{
  @Output('selectedSubtitleEvent') selectedSubtitle = new EventEmitter<string>()

  params!: IHeaderParams & ICustomHeaderParams;

  ascSort = 'inactive';
  descSort = 'inactive';
  noSort = 'inactive';

  menuRelatedTableTitles: any = []
  relatedTables: any;

  @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;
  ngOnInit():void {}

  agInit(params: IHeaderParams & ICustomHeaderParams): void {
    console.log(params)
    this.params = params;
    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );
    this.onSortChanged();
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  onSortChanged() {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }

  onSortRequested(order: 'asc' | 'desc' | null, event: any) {
    this.params.setSort(order, event.shiftKey);
  }

  refresh(params: IHeaderParams) {
    return false;
  }

  onOpenSelectionModal() {

  }

  subtitleEventTransporter(event:string) {
    console.log(event)
    this.selectedSubtitle.emit(event)
    console.log("this.params.api.getColumnDefs(): ",this.params.api.getColumnDefs())
    const columns = this.params.api.getColumnDefs()
    console.log('columnsDef: ', columns)
    const newField: ColDef = {
      field: "Select Field",
      width: 220,
      cellStyle: { color: 'red', 'background-color': 'rgb(204, 255, 255)' },
      headerClass: 'custom-header'
    };
    // @ts-ignore
    columns.pop()
    // @ts-ignore
    columns.push({field:event, })
    // @ts-ignore
    columns.push(newField)
    // @ts-ignore
    this.params.api.setColumnDefs(columns)
    // @ts-ignore
    this.params.displayName = event

  }


}
