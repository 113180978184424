import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DeriveNewFieldModalComponent} from "../../modals/derive-new-field-modal/derive-new-field-modal.component";
import {RelatedTableFirstLevel} from "../../../smartQuery/models/sample-related-table";

@Component({
  selector: 'app-nested-menu',
  templateUrl: './nested-menu.component.html',
  styleUrls: ['./nested-menu.component.scss']
})
export class NestedMenuComponent implements OnInit {
  constructor(public modalService: NgbModal) {
  }

  @Input('data') data?: any
  @Output('selectedSubtitleEvent') selectedSubtitle = new EventEmitter<string>()

  subtitles: any[] = []
  subTitleSelected: any[] = []
  menuRelatedTableTitles: any[] | undefined

  ngOnInit() {
    this.menuItemsInitializer();
  }

  menuItemsInitializer() {
    console.log('menuTableTitles:', Object.keys(this.data)) //["transactionSampleData", "customerRefundRecord"]
    console.log('data: ', this.data)
    this.menuRelatedTableTitles = Object.keys(this.data)
    for (const title of this.menuRelatedTableTitles) {
      this.subtitles.push(Object.keys(this.data[title][0]))
    }
  }

  showMenuClick(event: any) {
    console.log(event)
    this.selectedSubtitle.emit(event) //this is for adding a new column
  }

  changeSubtitle(i: number) {
    console.log(i)
    this.subTitleSelected = this.subtitles[i]
  }

  openDeriveNewFieldModal() {
    const modalRef = this.modalService.open(DeriveNewFieldModalComponent, {size: ''});
  }

}
