import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SmartQueryService} from "../../../smartQuery/_services/smart-query.service";
import {PreFilterParam} from "../../../smartQuery/models/pre-filter-param";

@Component({
  selector: 'app-input-filter-modal',
  templateUrl: './input-filter-modal.component.html',
  styleUrls: ['./input-filter-modal.component.scss']
})
export class InputFilterModalComponent {
  constructor(public activeModal: NgbActiveModal, private smartQueryService: SmartQueryService) {
  }

  smartQueryList: any[] = []

  // Main Entity
  mainEntityList: any[] = []

  mainEntity: { name: string, id: string } = null

  // Reference Entity
  selectedRefEntityList: any[] = [];

  logicSelector: string = null

  overallResult = {
    mainEntity: {},
    referenceEntity: []
  }

  preFilterMainParamWithoutChange: PreFilterParam
  preFilterReferenceParamCombineListWithoutChange: PreFilterParam[]

  ngOnInit() {
    this.overallResult.mainEntity = this.smartQueryService.getPreFilterMainParam()
    this.overallResult.referenceEntity = Object.assign([], this.smartQueryService.getPreFilterRefParam())

    // Make a copy of the param, for "Back" button
    this.preFilterMainParamWithoutChange = Object.assign({}, JSON.parse(JSON.stringify(this.smartQueryService.getPreFilterMainParam())))
    this.preFilterReferenceParamCombineListWithoutChange = Object.assign([], JSON.parse(JSON.stringify(this.smartQueryService.getPreFilterRefParam())))
  }

  /***
   * Close with save
   */
  closeModal() {
    const formData = this.overallResult
    this.activeModal.close(formData)
  }

  /***
   * Close without save
   */
  closeModalWithoutSave() {
    const formData = {mainEntity: this.preFilterMainParamWithoutChange, referenceEntity: this.preFilterReferenceParamCombineListWithoutChange};
    this.smartQueryService.setPreFilterMainParam(this.preFilterMainParamWithoutChange)
    this.smartQueryService.setPreFilterRefParam(this.preFilterReferenceParamCombineListWithoutChange)
    this.activeModal.close(formData)
  }

  onPreFilterParamChanged(preFilterParam: any, entityType: string, entityIndex: number) {
    if (entityType === 'main') { this.overallResult.mainEntity = preFilterParam }

    else {
      this.overallResult.referenceEntity[entityIndex] ?
      this.overallResult.referenceEntity[entityIndex] = preFilterParam :
      this.overallResult.referenceEntity.push(preFilterParam)
    }
  }
}
