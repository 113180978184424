import './polyfills';
//引入生產模式，控制關閉開發模式的
import {enableProdMode} from '@angular/core';
//建立要準備執行 Angular 應用程式的平台
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

//根模組(Root Module), 其中定義了bootstrap
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = () => {}
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// platformBrowserDynamic().bootstrapModule(AppModule, { ngZone: 'noop' })
//   .catch(err => console.error('Sys Err',err));
