import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContextDataResolver} from "./context-data.resolver";
import {ThemeComponent} from "./theme.component";
import {SmartQueryV2Component} from "./pages/smart-query-v2/smart-query-v2.component";
import {SmartQueryV3Component} from "./pages/smart-query-v3/smart-query-v3.component";
// two way for load module
// 1. preLoad: loadChildren: () => import().then()
// 2. lazyLoad: loadChildren: ""

const routes: Routes = [
  {
    path: '',
    component: ThemeComponent,
    resolve: {
      rsContextData: ContextDataResolver
    },
    children: [
      // {
      //   path: '',
      //   redirectTo: 'dashboard',
      //   pathMatch: 'full',
      // },
      // {path: "error", loadChildren: "./pages/error/error.module#ErrorsModule"},
      // {path: "documentation/:type", loadChildren: "./pages/documentation/documentation.module#DocumentationModule"},
      {path: "dashboard", loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)},
      {path: "notification/:type", loadChildren: () => import('./pages/notificationDashboard/notificationDashboard.module').then(m => m.NotificationDashboardModule)},
      {path: "data-ware-house", loadChildren: () => import('./pages/dataWareHouse/dataWareHouseMain.module').then(m => m.DataWareHouseMainModule)},
      {path: "data-pipeline", loadChildren: () => import('./pages/dataPipeline/dataPipelineMain.module').then(m => m.DataPipelineMainModule)},
      {path: "user-group", loadChildren: () => import('./pages/userGroup/userGroupMain.module').then(m => m.UserGroupMainModule)},
      {path: "access-control", loadChildren: () => import('./pages/accessControl/accessControl.module').then(m => m.AccessControlModule)},
      {path: "admin-setting/:type", loadChildren: () => import('./pages/adminSetting/adminSettingMain.module').then(m => m.AdminSettingMainModule)},
      {path: "smart-query", loadChildren: () => import('./pages/smartQuery/smartQueryMain.module').then(m => m.SmartQueryMainModule)},
      {path:"data-comparator", loadChildren:()=>import('./pages/dataComparator/dataComparatorMain.module').then(m=>m.DataComparatorMainModule)},
      {path: "smart-query-V2", component: SmartQueryV2Component},
      {path: "smart-query-V3", loadChildren: () => import('./pages/smart-query-v3/smart-query-v3.module').then(m => m.SmartQueryV3Module)},
      {path: "smart-search", loadChildren: () => import('./pages/customerDashboard/customerDashboardMain.module').then(m => m.CustomerDashboardMainModule)},
      {path: "crawler", loadChildren: () => import('./pages/crawler/crawler.module').then(m => m.CrawlerMainModule)},
      {path: "content-hub", loadChildren: () => import('./pages/contentHub/contentHubMain.module').then(m => m.ContentHubMainModule)},
      {path: "chatbot", loadChildren: () => import('./pages/chatbot/chatbotMain.module').then(m => m.ChatbotMainModule)},
      {path: "llm-model", loadChildren: () => import('./pages/llmModel/chatbotMain.module').then(m => m.LLMModelMainModule)},
      {path: "account", loadChildren: () => import('./pages/account/accountMain.module').then(m => m.AccountMainModule)},

      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      // },
      // {
      //   path: 'data-ware-house',
      //   loadChildren: () =>
      //     import('./pages/dataWareHouse/dataWareHouseMain.module').then((m) => m.DataWareHouseMainModule),
      // },
      // {
      //   path: 'data-pipeline',
      //   loadChildren: () =>
      //     import('./pages/dataPipeline/dataPipelineMain.module').then((m) => m.DataPipelineMainModule),
      // },
      // {
      //   path: 'userGroup',
      //   loadChildren: () =>
      //     import('./pages/userGroup/adminMain.module').then((m) => m.UserGroupMainModule),
      // },
      // {
      //   path: 'smart-query',
      //   loadChildren: () =>
      //     import('./pages/smartQuery/smartQueryMain.module').then((m) => m.SmartQueryMainModule),
      // },
      // {
      //   path: 'account',
      //   loadChildren: () =>
      //     import('./pages/account/accountMain.module').then((m) => m.AccountMainModule),
      // },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  // declarations: [ThemeComponent],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    ContextDataResolver,
  ]
})
export class ThemeRoutingModule { }
